// Read more component
class ReadMore {
    // initialize
    constructor () {
        const readMoreLinks = document.querySelectorAll('[data-read-more]');
        const self = this;

        if (null !== readMoreLinks.length) {
            readMoreLinks.forEach(function (link) {
                link.addEventListener('click', evt => self.show(evt));
            });
        }

        const readLessLinks = document.querySelectorAll('[data-read-less]');

        if (null !== readLessLinks) {
            readLessLinks.forEach(function (link) {
                link.addEventListener('click', evt => self.hide(evt));
            });
        }
    }

    /**
     * Show "more" content
     *
     * @param event
     *
     * @return {boolean}
     */
    show(event) {
        event.preventDefault();

        event.target.classList.add('d-none');

        document.querySelector(event.target.dataset.readMore).classList.remove('d-none');

        let lessLink = document.querySelector('[data-read-less="'+event.target.dataset.readMore+'"]');

        if (null !== lessLink) {
            lessLink.classList.remove('d-none');
        }

        return false;
    }

    /**
     * Hide "more" content
     *
     * @param event
     *
     * @return {boolean}
     */
    hide(event) {
        event.preventDefault();

        event.target.classList.add('d-none');

        document.querySelector(event.target.dataset.readLess).classList.add('d-none');

        let moreLink = document.querySelector('[data-read-more="'+event.target.dataset.readLess+'"]').classList.remove('d-none');

        if (null !== moreLink) {
            moreLink.classList.remove('d-none');
        }

        return false;
    }
}

new ReadMore();